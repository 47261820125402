<template>
  <div>
    <div class="link-block">
      <div class="form-group">
        <label>Link</label>
        <div class="input-group add-on">
          <div class="input-group-btn">
            <div class="btn btn-default" style="cursor: default">
              {{ domain }} /</div>
          </div>
          <input type="text" class="form-control" name="link" placeholder="Enter link" required>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Shorten Link</label><div class="input-group add-on">
      <input type="text" class="form-control" placeholder="Shorten Link" id="shorten-link" readonly=""><div class="input-group-btn">
      <button class="btn btn-default" onclick="copy()" type="button"><i class="fa fa-copy"></i>
      </button></div></div>
      <i style="display: none;" class="info-msg"></i></div>
  </div>


</template>
<script>

export default {
  name: "details",
  mixins: [],
  props: {
    domain: {
      type: String,

    },
    options: {
      type: String,
      default:'prop2'
    },

    cassielid: {
      type: String,
      default:''
    },
  },
  components: {
  },
  data: () => ({
    rawHtml: '',
  }),
  mounted: function () {
        this.domain =  process.env.VUE_APP_PREVIEW_BASE_URL;


  },
  updated() {
  },
  methods: {
  }
};
</script>